// Setup Honeybadger
// import Honeybadger from '@honeybadger-io/javascript';

// if (process.env.HONEYBADGER_API_KEY) {
//   console.log(`Launching ${process.env.RAILS_ENV} Honeybadger Vue`);
//   Honeybadger.configure({
//     apiKey: process.env.HONEYBADGER_API_KEY,
//     environment: process.env.RAILS_ENV,
//   });
// }

const { id} = document.body;
// const { flashNotice, flashError } = dataset;

if (id === 'devise-sessions-new') {
  import('../javascript/views/devise/sessions/new.js');
}

// Setup header
const profileMenuLink = document.getElementById('profile-menu-link');
const profileDropdown = document.getElementById('profile-dropdown');

if (profileMenuLink && profileDropdown) {
  profileMenuLink.addEventListener('click', () => {
    // e.preventDefault();
    profileDropdown.classList.toggle('show');
  });
}
